<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs defaultActiveKey="0" :tabBarStyle="{
                margin: '0',
              }">
        <a-tab-pane key="0" :tab="stageName"> </a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="当前项目" class="default-highlight">
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" class="default-highlight">
              <a-input :disabled="true" :placeholder="projectName" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label="key" v-for="(value, key) in fileObj" :key="key" :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }">
              <FileItem @addFile="getFile" :name="key" :msg="selectFile[key]" />
            </a-form-item>
          </a-col>

        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileItem from "./components/FileItem";

import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  exportFile,
  downloadTemplate,
  fetchDetailByPid
} from "@/api/epc/purchase";
import { mapGetters } from "vuex";

import { groupBy, recoveryArr } from "@/utils/groupBy";

export default {
  name: "purchaseMain",
  components: {
    FileItem,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_purchase_main_contract_exe",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid: "",
      projectName: "",
      designCode: "",

      selectedUserList: [],
      templateList: ["合同变更评估表"],

      fileObj: {
        合同变更评估表: [],
        变更合同: [],
      },
      selectFile: {}, //选中的文件
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    this.getName();
    this.getDetailByPid()
  },
  methods: {
    async getDetailByPid(){
      let res = await fetchDetailByPid({id:this.pid,stage:this.stage})
      this.id = res.id
      this.hid = res.historyId
      const groupedPeople = groupBy(res.attachmentList, "type");
      this.selectFile = groupedPeople;
    },

    getFile(val) {
      // 根据name，替换选择的文件
      this.selectFile[val.name] = val.fileList;
      console.log(this.selectFile);
    },

    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    selectedUser(value) {
      this.selectedUserList = value;
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    onSelectContract(value) {
      console.log(value.contractAmount);
      this.form.setFieldsValue({
        contractCode: value.code,
      });
      this.form.setFieldsValue({
        contractPrice: value.contractAmount * 0.0001,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      // 对每个文件添加type
      for (let key in this.selectFile) {
        this.selectFile[key].forEach((item) => {
          item.type = key;
        });
      }

      var paramList = recoveryArr(this.selectFile);
      console.log(paramList);

      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          if (!this.id) {
            console.log("新增");
            console.log(this.msg);
            console.log(this.stage);

            add({
              ...values,
              pid: this.pid,
              designCode: this.designCode,
              projectName: this.projectName,
              attachments: paramList,
              type: this.stage,
            }).then(() => {
              this.getDetailByPid()
            });
          } else {
            edit({
              ...values,
              id: this.id,
              historyId: this.hid,
              pid: this.pid,
              designCode: this.designCode,
              projectName: this.projectName,
              attachments: paramList,
              type: this.stage,
            }).then(() => {
              this.getDetailByPid()
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
